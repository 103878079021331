import React, { useContext } from "react";
import { StationWaterYearSummaryContext } from "../../contexts/StationWaterYearSummaryContext";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  padded: {
    padding: theme.spacing(1),
},
  dailyPrecipCell: {
      backgroundColor: '#CCF'
  },
  multidayCell: {
      backgroundColor: '#CFF'
  },
  missingCell: {
      backgroundColor: '#E4E4E4'
  },
  conflictCell: {
      backgroundColor: '#F00'
  },
  defaultCell: {
      
  }
}));

function GetDailyCellClass(classes, day) {
    if(day.dailyPrcpValue == null && day.multidayPrcp == null) {
        return classes.missingCell;
    }
    else if(day.dailyPrcpValue > 0) {
        return classes.dailyPrecipCell;
    } 
    else if(day.dailyPrcp === 'T') {
        return classes.dailyPrecipCell;
    }
    else {
        return classes.defaultCell;
    }
}

function GetMultidayCellClass(classes, day) {
  if(day.dailyPrcpValue != null && day.multidayPrcp != null) {
      return classes.conflictCell;
  }
  else if(day.multidayPrcp != null) {
      return classes.multidayCell;
  } 
  else {
      return classes.defaultCell;
  }
}

function CreateDaysInWyTable(classes, stationWys, months, monthsText) {

  return (
    <TableContainer component={Paper}>
      <Typography className={classes.padded} variant="h6" color="primary">
        Days in Water Year ({monthsText})
      </Typography>
      <Table
        className={classes.table}
        size="small"
        aria-label="Water Year Overview"
      >
        <TableHead>
          <TableRow>
            <TableCell>Day of Year</TableCell>
            <TableCell>Daily Precip</TableCell>
            <TableCell>Multiday Precip</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stationWys.daysInWaterYear
            .filter((day) => {
              return months.includes(day.day.substring(5, 7));
            })
            .map((row, index) => (
              <TableRow key={row.day}>
                <TableCell component="th" scope="row" width="80rem" >
                  {row.day.substring(0, 10)}
                </TableCell>
                <TableCell align="right" className={GetDailyCellClass(classes, row)} >{row.dailyPrcp}</TableCell>
                <TableCell align="right" className={GetMultidayCellClass(classes, row)} >{row.multidayPrcp}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function StationDaysInWaterYear() {
    const { stationWys } = useContext(StationWaterYearSummaryContext);
    const classes = useStyles();

  if (!stationWys) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Paper>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={4}>Days in Water Year Color Key</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.dailyPrecipCell}></TableCell>
                        <TableCell>Daily Observation with Precip</TableCell>
                        <TableCell className={classes.missingCell}></TableCell>
                        <TableCell>Missing Day</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.multidayCell}></TableCell>
                        <TableCell>Multiday Observation</TableCell>
                        <TableCell className={classes.conflictCell}></TableCell>
                        <TableCell>Daily/Multiday Observation Conflict</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </Paper>
        </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {CreateDaysInWyTable(classes, stationWys, ["10", "11", "12"], "Oct - Dec")}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {CreateDaysInWyTable(classes, stationWys, ["01", "02", "03"], "Jan - Mar")}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {CreateDaysInWyTable(classes, stationWys, ["04", "05", "06"], "Apr - Jun")}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {CreateDaysInWyTable(classes, stationWys, ["07", "08", "09"], "Jul - Sep")}
      </Grid>
    </Grid>
  );
}

export default StationDaysInWaterYear;
