import React, { useContext } from "react";
import { StationWaterYearSummaryContext } from "../../contexts/StationWaterYearSummaryContext";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { formatPrecip, formatSnow } from "../../constants"

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 450,
  },
  padded: {
    padding: theme.spacing(2),
  },
}));


/*
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
*/
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function StationMonthlySummaryList() {
  const { stationWys } = useContext(StationWaterYearSummaryContext);
  const classes = useStyles();

  if (!stationWys) {
    return <></>;
  }

  return (
    <Paper className={classes.padded}>
      <TableContainer >
        <Typography variant="h5" color="primary">
          Water Year Overview
        </Typography>
        <Table
          className={classes.table}
          size="small"
          aria-label="Water Year Overview"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{whiteSpace: "nowrap"}}>Year-Month</TableCell>
              { stationWys.hasPrismData && 
                <TableCell>30 Yr Avg by PRISM</TableCell>
              }
              <TableCell>Total Precip Sum</TableCell>
              <TableCell>Days Covered by All Obs</TableCell>
              <TableCell>Daily Precip Sum</TableCell>
              <TableCell>Daily Obs Count</TableCell>
              <TableCell>Multiday Precip Sum</TableCell>
              <TableCell>Days Convered by Multiday Obs</TableCell>
              <TableCell>Multiday Obs Count</TableCell>
              <TableCell>Days with Precip</TableCell>
              <TableCell>Days with Trace</TableCell>
              <TableCell>Total Snowfall</TableCell>
              <TableCell>Days with Snowfall</TableCell>
              <TableCell>Days with Snow on Ground</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stationWys.monthlyTotals.map((row, index) => (
              <StyledTableRow key={`${row.shortMonthName} ${row.year}`}>
                <TableCell component="th" scope="row"  style={{whiteSpace: "nowrap"}}>
                  {row.shortMonthName} {row.year}
                </TableCell>
                { stationWys.hasPrismData && 
                  <TableCell align="right">{formatPrecip(row.prism30YrAvgPrcp)}</TableCell>
                }
                <TableCell align="right">{formatPrecip(row.totalPrcpSum)}</TableCell>
                <TableCell align="right">
                  {row.daysCoveredByAllObservationsCount}
                </TableCell>
                <TableCell align="right">{formatPrecip(row.dailyPrcpSum)}</TableCell>
                <TableCell align="right">
                  {row.dailyPrcpObservationCount}
                </TableCell>
                <TableCell align="right">{formatPrecip(row.multidayPrcpSum)}</TableCell>
                <TableCell align="right">{row.daysCoveredByMultidayPrcpObservationsCount}</TableCell>
                <TableCell align="right">{row.multidayPrcpObservationCount}</TableCell>
                <TableCell align="right">{row.daysWithPrcpCount}</TableCell>
                <TableCell align="right">{row.daysWithTraceCount}</TableCell>
                <TableCell align="right">{formatSnow(row.totalSnowfall)}</TableCell>
                <TableCell align="right">{row.daysWithSnowfallCount}</TableCell>
                <TableCell align="right">{row.daysWithSnowOnGroundCount}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell align="right">Totals:</TableCell>
              { stationWys.hasPrismData &&   
                <TableCell align="right">
                  {formatPrecip(stationWys.monthlyTotals.reduce(
                    (total, current) => total + current.prism30YrAvgPrcp,
                    0
                  ))}
                </TableCell>
              }
              <TableCell align="right">
                {formatPrecip(
                  stationWys.monthlyTotals.reduce(
                    (total, current) => total + current.totalPrcpSum,
                    0
                  )
                )}
              </TableCell>
              <TableCell align="right">
                {stationWys.monthlyTotals.reduce(
                  (total, current) =>
                    total + current.daysCoveredByAllObservationsCount,
                  0
                )}
              </TableCell>
              <TableCell align="right">
                {formatPrecip(
                  stationWys.monthlyTotals.reduce(
                    (total, current) => total + current.dailyPrcpSum,
                    0
                  )
                )}
              </TableCell>
              <TableCell align="right">
                {stationWys.monthlyTotals.reduce(
                  (total, current) => total + current.dailyPrcpObservationCount,
                  0
                )}
              </TableCell>
              <TableCell align="right">
                {formatPrecip(stationWys.monthlyTotals.reduce(
                  (total, current) => total + current.multidayPrcpSum,
                  0
                ))}
              </TableCell>
              <TableCell align="right">
                {stationWys.monthlyTotals.reduce(
                  (total, current) => total + current.daysCoveredByMultidayPrcpObservationsCount,
                  0
                )}
              </TableCell>
              <TableCell align="right">
                {stationWys.monthlyTotals.reduce(
                  (total, current) => total + current.multidayPrcpObservationCount,
                  0
                )}
              </TableCell>
              <TableCell align="right">
                {stationWys.monthlyTotals.reduce(
                  (total, current) => total + current.daysWithPrcpCount,
                  0
                )}
              </TableCell>
              <TableCell align="right">
                {stationWys.monthlyTotals.reduce(
                  (total, current) => total + current.daysWithTraceCount,
                  0
                )}
              </TableCell>
              <TableCell align="right">
                {formatSnow(stationWys.monthlyTotals.reduce(
                  (total, current) => total + current.totalSnowfall,
                  0
                ))}
              </TableCell>
              <TableCell align="right">
                {stationWys.monthlyTotals.reduce(
                  (total, current) => total + current.daysWithSnowfallCount,
                  0
                )}
              </TableCell>
              <TableCell align="right">
                {stationWys.monthlyTotals.reduce(
                  (total, current) => total + current.daysWithSnowOnGroundCount,
                  0
                )}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default StationMonthlySummaryList;
