import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  padded: {
      padding: theme.spacing(2),
  }
}));

function About() {

    const classes = useStyles();
  return (
    <Paper className={classes.padded}>
      <Typography variant="h4" color="primary">About the Water Year</Typography>
      <Typography className={classes.padded}>
      Hydrologists and climatologists often use the "Water Year" calendar for tracking water resources. While the definition may vary regionally, a common definition of Water Year is October 1 through September 30. This definition of Water Year is used by the U.S. Geological Survey (USGS) especially when dealing with surface water supplies. It is intended to define the period from the beginning of the wet season through the end of the demand season.  Chances are that if you live east of the Rocky Mountains where precipitation is plentiful year around, on average, the concept of Water Year is unfamiliar.
      </Typography>
      <Typography className={classes.padded}>
      In the western U.S. during the cool months evaporation rates are very low, snow accumulates in cold regions and mountains, and storms tend to be larger and produce precipitation over wide areas. When the dry season begins in the late spring surface water supplies rely on snow melt and high runoff from mountainous regions and higher latitudes. The summer months bring high evaporation rates. Precipitation becomes much more localized, and some areas out west go for long periods without any significant rainfall. Streams run slower and clearer.
      </Typography>
      <Box display="flex"
          justifyContent="center"
          alignItems="center"
          >
          <Paper style={{width: "80%", padding: "20px"}}>
            <img src="/images/about-waterdemand-chart.png" style={{width: "100%"}} />
          </Paper>
      </Box>
      <Typography className={classes.padded}>
      The first map is the proportion of annual precipitation that occurs across the U.S. from October 1 to April 30, considered to be the wet season in much of the area west of the Rockies. Note the high precipitation amounts in the Pacific Northwest south to the Sierra Nevada. The second map is the proportion of annual precipitation from May 1 to September 30. The western U.S. is relatively dry, while the eastern U.S. is wet by contrast. A water year does not split the fall and winter seasons because the majority of storms along the west coast (and the snows that produce western water supply) occur between October and May. For reference the average annual precipitation for the two periods is also shown. Note the maps have different scales.  
      </Typography>
      <Box display="flex"
          justifyContent="center"
          alignItems="center"
          mb={6}
          >
          <Paper style={{width: "80%", padding: "20px"}}>
            <img src="/images/about-prism-precipmaps.png" style={{width: "100%"}} />
          </Paper>
      </Box>
      <Box display="flex"
          justifyContent="center"
          alignItems="center">     
          <Paper style={{width: "80%", padding: "20px"}}>
            <img src="/images/about-mrcc-precipmaps.png" style={{width: "100%"}} />
          </Paper>        
      </Box>
      <Typography className={classes.padded}>
        CoCoRaHS recommends this Water Year calendar for tabulating precipitation amounts. If you are a CoCoRaHS observer, you can access your Water Year Summary reports for your station(s) from here or the "<a href="https://www.cocorahs.org/Admin/MyProfile/" target="_blank">My Account</a>" page of the main website.
      </Typography>
    </Paper>
  );
}

export default About;
