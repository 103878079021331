import { useEffect, useState } from "react";
import axios from "axios";

export const REQUEST_STATUS = {
    LOADING: "loading",
    SUCCESS: "success",
    FAILURE: "failure",
  };

const restUrl = "https://wys-api.cocorahs.org/api/StateWysJson";

function useStateWaterYearSummary(intiStateAbbr, initWaterYear) {
    const [stateWys, setStateWys] = useState();
    const [stateAbbr, setStateAbbr] = useState(typeof(intiStateAbbr) !== 'undefined' ? intiStateAbbr.toUpperCase() : null);
    const [waterYear, setWaterYear] = useState(typeof(initWaterYear) !== 'undefined' ? initWaterYear : 2021);
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.SUCCESS);
    const [error, setError] = useState("");

    useEffect(() => {
        async function delayFunc() {
            try {
                const result = await axios.get(`${restUrl}?state=${stateAbbr}&year=${waterYear}`);
                setRequestStatus(REQUEST_STATUS.SUCCESS);
                setStateWys(result.data);
            } catch (e) {
                setError(`An error occurred trying to retrieve the ${waterYear} water year summary reports for ${stateAbbr}.`);
                setRequestStatus(REQUEST_STATUS.FAILURE);
            }
        }
        if(stateAbbr && waterYear) {
            setRequestStatus(REQUEST_STATUS.LOADING);
            delayFunc();
        }
    }, [stateAbbr, waterYear]);
/*
    useEffect(() => {
        const fetchStateWys = async () => {
            const rsp = await fetch("/stateWys_CO_2020.json");
            const wys = await rsp.json();
            setStateWys(wys);
        }
        fetchStateWys();
    }, []);
*/
  
    return {
        stateWys,
        requestStatus,
        error,
        stateAbbr,
        setStateAbbr,
        waterYear,
        setWaterYear
    };
}



export default useStateWaterYearSummary;