import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  root2: {
    flexGrow: 1,
  },
  root: {
    flexGrow: 1,
    background: "linear-gradient(45deg, #ececec 30%, #b0c4de 90%)", //dark blue #0C2E82, light blue #b0c4de
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(100, 100, 100, .3)",
    color: "white",
  },
  buttonGroup: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function Header() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <a href="http://www.cocorahs.org">
        <img
          src="/images/cocorahs_logo_shaped_trans.png"
          alt="logo"
          height="60rem"
        />
        </a>
        <Typography color="textPrimary" variant="h6" className={classes.title}>
          <Hidden smDown>
            <Link to="/" onClick={() => history.push("/")} style={{color:"inherit", textDecoration:"none"}}>CoCoRaHS Water Year Summary Reports</Link>
          </Hidden>
          <Hidden  only={['xs', 'md', 'lg', 'xl']}>
            <Link to="/" onClick={() => history.push("/")} style={{color:"inherit", textDecoration:"none"}}>CoCoRaHS WYS Reports</Link>
          </Hidden>
        </Typography>
        <ButtonGroup
          variant="text"
          color="primary"
          aria-label="text primary button group"
        >
          <Button onClick={() => history.push("/")} >Home</Button>
          <Button onClick={() => history.push("/about")} >About</Button>
          <Button onClick={() => history.push("/help")} >Help</Button>
          <Button onClick={() => window.open(
          'https://form.jotform.com/212634839464160',
          'blank',
          'scrollbars=yes, toolbar=no, width=700, height=500'
        )} >Feedback</Button>
        </ButtonGroup>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
