import React, { createContext, useEffect } from "react";
import { useParams, useRouteMatch  } from "react-router-dom";
import useStateWaterYearSummary from "../hooks/useStateWaterYearSummary";
import { CURRENT_WATER_YEAR, isWaterYearValid } from '../constants'

const StateWaterYearSummaryContext = createContext();

function StateWaterYearSummaryProvider({ children }) {
  let { stateAbbr: initStateAbbr, waterYear: initWaterYear } = useRouteMatch().params; // useParams();

  // validate water year
  if (!(isWaterYearValid(initWaterYear))) {
    initWaterYear = CURRENT_WATER_YEAR;
  }
  const {
    stateWys,
    setWaterYear,
    setStateAbbr,
    stateAbbr,
    waterYear,
    requestStatus,
    error,
  } = useStateWaterYearSummary(initStateAbbr, initWaterYear);

  useEffect(() => {
    setWaterYear(initWaterYear);
    setStateAbbr(initStateAbbr);
  }, [initStateAbbr, initWaterYear]);

  return (
    <StateWaterYearSummaryContext.Provider
      value={{
        stateWys,
        setWaterYear,
        setStateAbbr,
        stateAbbr,
        waterYear,
        requestStatus,
        error,
      }}
    >
      {children}
    </StateWaterYearSummaryContext.Provider>
  );
}

export { StateWaterYearSummaryContext, StateWaterYearSummaryProvider };
