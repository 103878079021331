import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton
  } from '@material-ui/data-grid';

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  export default CustomToolbar;