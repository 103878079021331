import React, {useContext} from "react";
import { Paper } from '@material-ui/core'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { StationWaterYearSummaryContext } from '../../contexts/StationWaterYearSummaryContext';

function getMonthlyChartSeries(wys) {
    const series = [{
        name: 'Daily Prcp Monthly Total',
        stack: 0,
        shadow: false,
        color: '#4572A7', // blue
        data: [wys.monthlyTotals[0].dailyPrcpSum, wys.monthlyTotals[1].dailyPrcpSum, wys.monthlyTotals[2].dailyPrcpSum, wys.monthlyTotals[3].dailyPrcpSum,
                        wys.monthlyTotals[4].dailyPrcpSum, wys.monthlyTotals[5].dailyPrcpSum, wys.monthlyTotals[6].dailyPrcpSum, wys.monthlyTotals[7].dailyPrcpSum,
                        wys.monthlyTotals[8].dailyPrcpSum, wys.monthlyTotals[9].dailyPrcpSum, wys.monthlyTotals[10].dailyPrcpSum, wys.monthlyTotals[11].dailyPrcpSum]

    }, {
        name: 'Multiday Prcp Monthly Total',
        stack: 0,
        shadow: false,
        color: '#89A54E', // purple:#80699B aqua:3D96AE lightneonblue:99FFFF CCFFFF
        data: [wys.monthlyTotals[0].multidayPrcpSum, wys.monthlyTotals[1].multidayPrcpSum, wys.monthlyTotals[2].multidayPrcpSum, wys.monthlyTotals[3].multidayPrcpSum,
                        wys.monthlyTotals[4].multidayPrcpSum, wys.monthlyTotals[5].multidayPrcpSum, wys.monthlyTotals[6].multidayPrcpSum, wys.monthlyTotals[7].multidayPrcpSum,
                        wys.monthlyTotals[8].multidayPrcpSum, wys.monthlyTotals[9].multidayPrcpSum, wys.monthlyTotals[10].multidayPrcpSum, wys.monthlyTotals[11].multidayPrcpSum]

    }, {
        name: 'Snowfall Monthly Total',
        yAxis: 1,
        stack: 1,
        shadow: false,
        color: '#80699B', // green:#89A54E
        data: [wys.monthlyTotals[0].totalSnowfall, wys.monthlyTotals[1].totalSnowfall, wys.monthlyTotals[2].totalSnowfall, wys.monthlyTotals[3].totalSnowfall,
                        wys.monthlyTotals[4].totalSnowfall, wys.monthlyTotals[5].totalSnowfall, wys.monthlyTotals[6].totalSnowfall, wys.monthlyTotals[7].totalSnowfall,
                        wys.monthlyTotals[8].totalSnowfall, wys.monthlyTotals[9].totalSnowfall, wys.monthlyTotals[10].totalSnowfall, wys.monthlyTotals[11].totalSnowfall]

    }];
    // Add series for PRISM normals if available
    if (wys.hasPrismData) {
        var prismSeries = {
            type: 'line',
            name: '30 Year Avg by PRISM',
            data: [wys.prism30YrMonthlyAvgPrcp.oct, wys.prism30YrMonthlyAvgPrcp.nov, wys.prism30YrMonthlyAvgPrcp.dec, wys.prism30YrMonthlyAvgPrcp.jan, wys.prism30YrMonthlyAvgPrcp.feb,
                    wys.prism30YrMonthlyAvgPrcp.mar, wys.prism30YrMonthlyAvgPrcp.apr, wys.prism30YrMonthlyAvgPrcp.may, wys.prism30YrMonthlyAvgPrcp.jun, wys.prism30YrMonthlyAvgPrcp.jul,
                    wys.prism30YrMonthlyAvgPrcp.aug, wys.prism30YrMonthlyAvgPrcp.sep]
        };
        series.push(prismSeries);
    }
    return series;
}

function getMapOptions(wys) {
    const waterYear = wys.waterYear;
    const startYear = waterYear - 1;

    return {
        chart: {
          type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
          text: `Monthly Precipitation for the ${waterYear} Water Year  (Oct ${startYear}  - Sept ${waterYear})`
        },
        subtitle: {
            text: `Station: ${wys.station.stationNumber}  ${wys.station.stationName}`
        },
        xAxis: {
            categories: [
                        'Oct ' + startYear,
                        'Nov ' + startYear,
                        'Dec ' + startYear,
                        'Jan ' + waterYear,
                        'Feb ' + waterYear,
                        'Mar ' + waterYear,
                        'Apr ' + waterYear,
                        'May ' + waterYear,
                        'Jun ' + waterYear,
                        'Jul ' + waterYear,
                        'Aug ' + waterYear,
                        'Sep ' + waterYear
                    ]
        },
        yAxis: [{
            min: 0,
            title: {
                text: 'Monthly Precipitation (inches)',
                color: '#4572A7' // blue
            }
        }, { // Secondary yAxis for Snowfall Accumulation 
            min: 0,
            title: {
                text: 'Monthly Snowfall (inches)',
                style: {
                    color: '#80699B' // purple
                }
            },
            opposite: true
        }],
        legend: {
            layout: 'horizontal', // 'vertical'
            // backgroundColor: '#FFFFFF',
            backgroundColor: {
                linearGradient: [0, 0, 0, 200],
                stops: [
                            [0, '#FFFFFF'],
                            [1, '#E0E0E0']
                        ]
            },
            shadow: true,
            align: 'left',
            verticalAlign: 'top',
            x: 56, // 100
            y: 56, //70
            floating: true
        },
        tooltip: {
            shared: true,
            backgroundColor: {
                linearGradient: [0, 0, 0, 200],
                stops: [
                            [0, '#FFFFFF'],
                            [1, '#E0E0E0']
                        ]
            },
            style: {
                padding: 10,
                fontWeight: 'bold',
                'line-height': 2
            },
            borderWidth: 1,
            borderColor: '#AAA'/*,
            formatter: function () {
                var s = '<b>' + this.x + '</b>';
                var pointsRef = this.points;
    
                $.each(pointsRef, function (i, point) {
                    s += '<br/><span style="color:' + point.series.color + '">' + point.series.name + ':</span>' +
                                Highcharts.numberFormat(point.y, 2) + '"';
    
                    // insert combined daily + multiday prcp
                    if (i == 1) {
                        s += '<br/><span style="color:black">Combined (Daily + Multiday) Prcp:</span> ' +
                                Highcharts.numberFormat((pointsRef[0].y + pointsRef[1].y), 2) + '"';
                    }
                });
    
    
                return s;
            } */
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                stacking: 'normal'
            }
        },
        series: getMonthlyChartSeries(wys)
      };
} 



function MonthlyPrecipTotalsChart() {
  const {stationWys} = useContext(StationWaterYearSummaryContext);

    if(!stationWys) {
        return <></>
    } 

    return (
        <Paper>
            <HighchartsReact highcharts={Highcharts} options={getMapOptions(stationWys)} />
        </Paper>
    );
};

export default MonthlyPrecipTotalsChart;