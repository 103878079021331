import React, { useContext } from "react";
import { StationWaterYearSummaryContext } from "../../contexts/StationWaterYearSummaryContext";
import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import RenderCellExpand from "../RenderCellExpand";
import { TRACE_VALUE, precipCellRenderer, timeCellRenderer, snowCellRenderer } from "../../constants"
import { parse } from 'date-fns'
import CustomToolbar from '../CustomToolBar';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 450,
    minHeight: 600,
  },
  padded: {
    padding: theme.spacing(2),
  },
}));

function StationDailyPrecipObsList() {
  const { stationWys } = useContext(StationWaterYearSummaryContext);
  const classes = useStyles();
  const refDate = new Date();

  const columns = [
    {
      field: "obsDateTime",
      headerName: "Obs Date",
      type: "date",
      width: 140,
      align: "right",
      valueFormatter: (params) => {
        return params.value.substring(0, 10);
      },
    },
      {
      field: "obsTime",
      headerName: "Obs Time",
      type: "date",
      width: 140,
      renderCell: timeCellRenderer,
    },
    {
      field: "precip",
      headerName: "Precip",
      type: "number",
      width: 120,
      renderCell: precipCellRenderer
      /*
      valueFormatter: precipGridFormatter,
      valueGetter: precipGetter,
      getApplyFilterFn: (filterItem, column) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }
        return (params) => {
          return Number(params.value) >= Number(filterItem.value);
        };
      },
      */
    },
    {
      field: "snowfall",
      headerName: "Snowfall",
      type: "number",
      width: 140,
      renderCell: snowCellRenderer
    },
    {
      field: "snowfallSwe",
      headerName: "Snowfall SWE",
      type: "number",
      width: 160,
      renderCell: precipCellRenderer
    },
    {
      field: "snowDepth",
      headerName: "Snowpack Depth",
      type: "number",
      width: 160,
      renderCell: snowCellRenderer
    },
    {
      field: "snowDepthSwe",
      headerName: "Snowpack SWE",
      type: "number",
      width: 160,
      renderCell: precipCellRenderer
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      renderCell: RenderCellExpand,
    },
    {
      field: "flooding",
      headerName: "Flooding",
      width: 200,
      renderCell: RenderCellExpand,
    },
  ];

  if (!stationWys) {
    return <></>;
  }

  return (
    <Paper className={classes.padded}>
      <Typography variant="h5" color="primary">
        Daily Precip Observations
      </Typography>
      {stationWys.dailyPrcpObservations.length > 0 ? (
        <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
        <DataGrid
          className={classes.table}
          density="compact"
          rows={stationWys.dailyPrcpObservations.map((obs) => {
            return {
              ...obs,
              id: obs.obsDateTime,
              precip: (obs.precipitationIsTrace)? TRACE_VALUE : obs.precipitation,
              snowfall: (obs.snowfallIsTrace)? TRACE_VALUE : obs.snowfall,
              snowfallSwe: (obs.snowfallSweIsTrace)? TRACE_VALUE : obs.snowfallSwe,
              snowDepth: (obs.snowDepthIsTrace)? TRACE_VALUE : obs.snowDepth,
              snowDepthSwe: (obs.snowDepthSweIsTrace)? TRACE_VALUE : obs.snowDepthSwe,
              obsTime: parse(obs.obsDateTime.substring(0, 16), "yyyy-MM-dd'T'HH:mm", refDate)
            };
          })}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
          </div>
</div>
      ) : (
        <Typography variant="h6">
          No daily precip observations were submitted.
        </Typography>
      )}
    </Paper>
  );
}

export default StationDailyPrecipObsList;
