import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
  media: {
    height: 240,
  },
  padded: {
    padding: theme.spacing(2),
  }
}));

function Help() {

    const classes = useStyles();
  return (
    <Paper className={classes.padded}>      
      <Grid container spacing={1} 
      direction="row"
      justifyContent="center"
      alignItems="center">
      <Grid item xs={12} >
      <Typography variant="h4" color="primary">Help with the Water Year Summary Reports</Typography>
        <Typography className={classes.padded}>
          To learn more about the features of the water year summary reports, please check out the help video and help document. 
          The feedback survey is available for you to let us know how we can improve the water year summary reports through bug fixes or feature additions. 
          Any additional questions can be emailed to us at <a href="mailto:info@cocorahs.org?subject=Water Year Summary Reports" >info@cocorahs.org</a>. 
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} align="center">
        <Card className={classes.root}>
          <CardActionArea onClick={() => window.open("https://youtu.be/-rErA0G1nnU" )}>
            <CardMedia
              className={classes.media}
              image="/images/help-video-thumbnail.png"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Help Video
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                The help video provides an overview of the features available in the water year summary reports search and for individual station reports. 
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button color="primary" onClick={() => window.open("https://youtu.be/-rErA0G1nnU" )} >
              Watch Help Video
            </Button>
          </CardActions>
        </Card>
        </Grid>
        <Grid item xs={12} md={6} align="center">
        <Card className={classes.root}>
          <CardActionArea onClick={() => window.open("/wys-app-help.pdf" )}>
            <CardMedia
              className={classes.media}
              image="/images/help-pdf-thumbnail.png"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Help Document
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                The PDF help documentation provides an overview of the features available in the water year summary reports search and for individual station reports. 
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button color="primary" onClick={() => window.open("/wys-app-help.pdf" )} >
              View the Help Document
            </Button>
          </CardActions>
        </Card>
        </Grid>
      </Grid>


    </Paper>
  );
}

export default Help;
