import React, { useContext } from "react";
import { StationWaterYearSummaryContext } from "../../contexts/StationWaterYearSummaryContext";
import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import RenderCellExpand from "../RenderCellExpand";
import { hailStoneCellRenderer } from "../../constants"; 
import CustomToolbar from '../CustomToolBar';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    minHeight: 100,
  },
  padded: {
    padding: theme.spacing(2),
  },
}));

function StationHailObsList() {
  const { stationWys } = useContext(StationWaterYearSummaryContext);
  const classes = useStyles();

  const columns = [
    {
      field: "obsDateTime",
      headerName: "Obs Date",
      type: "date",
      width: 160,
      valueFormatter: (params) => {
        return params.value.substring(0, 10);
      },
    },/*
    {
      field: "obsDateTime",
      headerName: "Obs Time",
      width: 200,
      valueFormatter: (params) => {
        return params.value.substring(10, 5);
      },
    },*/
    {
      field: "largestSize",
      headerName: "Largest Stone Size",
      width: 200,
      renderCell: hailStoneCellRenderer,
    },
    {
      field: "averageSize",
      headerName: "Avg Stone Size",
      width: 200,
      renderCell: hailStoneCellRenderer,
    },    
    {
      field: "smallestSize",
      headerName: "Smallest Stone Size",
      width: 200,
      renderCell: hailStoneCellRenderer,
    },
    {
      field: "stoneConsistency",
      headerName: "Stone Consistency",
      width: 200,
    },
    {
      field: "durationMinutes",
      headerName: "Duration Minutes",
      width: 200,
    },
    {
      field: "durationAccuracy",
      headerName: "Durration Accuracy",
      width: 200,
    },
    {
      field: "damage",
      headerName: "Damage",
      width: 200,
    },
    {
      field: "timing",
      headerName: "Timing",
      width: 200,
    },
    {
      field: "moreRainThanHail",
      headerName: "More Rain than Hail",
      width: 200,
    },
    {
      field: "hailStarted",
      headerName: "Hail Started",
      width: 200,
    },
    {
      field: "largestHailStarted",
      headerName: "Largest Hail Started",
      width: 200,
    },
    {
      field: "angleOfImpact",
      headerName: "Angle of Impact",
      width: 200,
    },
    {
      field: "numberOfStonesOnPad",
      headerName: "Number of Stones on Pad",
      width: 200,
    },
    {
      field: "distanceBtwnStonesOnPad",
      headerName: "Distance Between Stones on Pad",
      width: 200,
    },
    {
      field: "depthOnGround",
      headerName: "Depth of Stones on Ground",
      width: 200,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      renderCell: RenderCellExpand,
    },
  ];

  if (!stationWys) {
    return <></>;
  }

  return (
    <Paper className={classes.padded}>
      <Typography variant="h5" color="primary">
        Hail Observations
      </Typography>
      {stationWys.hailObservations.length > 0 ? (
        <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
        <DataGrid
          className={classes.table}
          density="compact"
          rows={stationWys.hailObservations.map((obs) => {
            return {
              ...obs,
              id: obs.dateTimeStamp,
            };
          })}
          columns={columns}
          autoHeight={true}
          hideFooterPagination={true}
          disableSelectionOnClick={true}
          hideFooter={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
        </div>
        </div>
      ) : (
        <Typography variant="h6">
          No hail observations were submitted.
        </Typography>
      )}
    </Paper>
  );
}

export default StationHailObsList;

/*
Observation Date	Observation Time	Notes	Largest Stone Size	Average Stone Size	Smallest Stone Size	Stone Consistency	Duration Minutes	Durration Accuracy	Damage	Timing	More Rain than Hail	Hail Started	Largest Hail Started	Angle of Impact	Number of Stones on Pad	Distance Between Stones on Pad	Depth of Stones on Ground

"HailObservations":[
{"CreationDate":"2020-07-11T13:53:00Z",
"DateTimeStamp":"2020-07-11T13:53:14.193Z",
"ObsDateTime":"2020-07-09T21:55:00+00:00",
"StationNumber":"CO-AR-381",
"Notes":"Largest hailstone recovered measured 3.5\"x3\"x1.75\".   Another round stone measured 2.5\" diameter.  Both of these stones had a jagged structure.  Photos available, shared with Boulder NWS.",
"LargestSize":3.000,
"AverageSize":1.500,
"SmallestSize":0.500,
"DurationMinutes":25
,"StoneConsistency":"Hard|Clear Ice|White Ice","DurationAccuracy":"3min","Timing":"Continuous","MoreRainThanHail":true,"HailStarted":"Before rain","LargestHailStarted":"Same time as smaller hail","AngleOfImpact":"30-40","NumberOfStonesOnPad":"","DistanceBtwnStonesOnPad":"","DepthOnGround":"","Damage":"minor leaf damage.damaged shingles"},
*/
