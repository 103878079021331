import { format } from "date-fns";

// Sequence generator function (commonly referred to as "range", e.g. Clojure, PHP etc)
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const FIRST_WATER_YEAR = 1999;
const CURRENT_WATER_YEAR = 2025;
const WATER_YEAR_RANGE = range(FIRST_WATER_YEAR, 2025, 1);
const TRACE_VALUE = 0.000001;
const TRACE_LABEL = "T";
const NA_LABEL = "NA";

const isWaterYearValid = (year) => {
  if (!year) {
    return false;
  }
  if (Number(year) === NaN) {
    return false;
  }
  if (WATER_YEAR_RANGE.indexOf(Number(year)) !== -1) {
    return true;
  }
  return false;
};

const formatPrecip = (val) => {
  return val === null ? NA_LABEL : val.toFixed(2);
};

const formatSnow = (val) => {
  return val ? val.toFixed(1) : "0.0";
};

const precipCellRenderer = (params) => {
  if (params.value === null) {
    return <>{NA_LABEL}</>;
  }
  if (params.value === TRACE_VALUE) {
    return <>{TRACE_LABEL}</>;
  }
  return <>{formatPrecip(params.value)}</>;
};

const snowCellRenderer = (params) => {
  if (params.value === null) {
    return <>{NA_LABEL}</>;
  }
  if (params.value === TRACE_VALUE) {
    return <>{TRACE_LABEL}</>;
  }
  return <>{formatSnow(params.value)}</>;
};

const dateCellRenderer = (params) => {
  return <>{format(params.value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")}</>;
};

const timeCellRenderer = (params) => {
  //return <>{params.value.toString()}</>;
  return <>{format(params.value, "HH:mm aaa")}</>;
};

const hailStoneCellRenderer = (params) => {
  if (params.value === null) {
    return <>{NA_LABEL}</>;
  }
  if (params.value < 0) {
    return <>{NA_LABEL}</>;
  }
  return <>{params.value.toFixed(2)}</>;
};

const DT_SEPERATOR = "T";
const DP_SEPERATOR = "-";
const TIME_SEPERATOR = ":";
const SEC_SEPERATOR = ".";
const TZ_SEPERATOR = "Z";
function parseIso8601(str) {
  // we assume str is a UTC date ending in 'Z'

  var parts = str.split(DT_SEPERATOR),
    dateParts = parts[0].split(DP_SEPERATOR),
    timeParts = parts[1].split(TZ_SEPERATOR),
    timeSubParts = timeParts[0].split(TIME_SEPERATOR),
    timeSecParts = timeSubParts[2].split(SEC_SEPERATOR),
    timeHours = Number(timeSubParts[0]);

  return Date.UTC(
    Number(dateParts[0]),
    Number(dateParts[1]) - 1,
    Number(dateParts[2])
  );
}

export {
  range,
  FIRST_WATER_YEAR,
  CURRENT_WATER_YEAR,
  WATER_YEAR_RANGE,
  isWaterYearValid,
  formatPrecip,
  formatSnow,
  TRACE_VALUE,
  NA_LABEL,
  precipCellRenderer,
  parseIso8601,
  dateCellRenderer,
  timeCellRenderer,
  snowCellRenderer,
  hailStoneCellRenderer,
};
