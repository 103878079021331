import React, { useContext } from "react";
import { StationWaterYearSummaryContext } from "../../contexts/StationWaterYearSummaryContext";
import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import RenderCellExpand from "../RenderCellExpand";
import { TRACE_VALUE, NA_LABEL, precipCellRenderer, timeCellRenderer, snowCellRenderer } from "../../constants"
import { parse, format } from 'date-fns'
import CustomToolbar from '../CustomToolBar';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 450,
    minHeight: 100,
  },
  padded: {
    padding: theme.spacing(2),
  },
}));

function StationMultidayObsList() {
  const { stationWys } = useContext(StationWaterYearSummaryContext);
  const classes = useStyles();
  const refDate = new Date();

  const columns = [
    {
      field: "obsStartDate",
      headerName: "Obs Start Date",
      type: "date",
      width: 160,
      valueFormatter: (params) => {
        return params.value.substring(0, 10);
      },
    },
    {
      field: "obsEndDateTime",
      headerName: "Obs End Date Time",
      type: "date",
      width: 160,
      valueFormatter: (params) => {
        return format(params.value, "yyyy-MM-dd HH:mm aaa");
      },
    },
    {
      field: "obsDurationInDays",
      headerName: "Obs Days",
      type: "number",
      width: 140,
    },
    {
      field: "precipitation",
      headerName: "Precip",
      type: "number",
      width: 140,
      renderCell: precipCellRenderer
    },
    {
      field: "snowDepth",
      headerName: "Snowpack Depth",
      type: "number",
      width: 160,
      renderCell: snowCellRenderer
    },
    {
      field: "snowDepthSwe",
      headerName: "Snowpack SWE",
      type: "number",
      width: 160,
      renderCell: precipCellRenderer
    },    
/*    {
      field: "snowRatio",
      headerName: "Snowpack SWR",
      description: "Snowpack Snow to Water Ratio",
      type: "number",
      width: 160,
      renderCell: (params) => {
        if(params.value === NA_LABEL) {
          return <>{NA_LABEL}</>
        } else {
          return <>{params.value}%</>
        }
      }
    }, */
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      renderCell: RenderCellExpand,
    },
  ];

  if (!stationWys) {
    return <></>;
  }

  return (
    <Paper className={classes.padded}>
      <Typography variant="h5" color="primary">
        Multiday Precip Observations
      </Typography>
      {stationWys.multiDayPrcpObservations.length > 0 ? (
        <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
        <DataGrid
          className={classes.table}
          density="compact"
          rows={stationWys.multiDayPrcpObservations.map((obs) => {
            return {
              ...obs,
              id: obs.dateTimeStamp,
              precipitation: (obs.precipitationIsTrace)? TRACE_VALUE : obs.precipitation,
              snowDepth: (obs.snowDepthIsTrace)? TRACE_VALUE : obs.snowDepth,
              snowDepthSwe: (obs.snowDepthSweIsTrace)? TRACE_VALUE : obs.snowDepthSwe,
           //   snowRatio: (obs.snowDepth && obs.snowDepthSwe)? ((obs.snowDepthSwe/obs.snowDepth)*100).toFixed(2) : NA_LABEL,
              obsEndDateTime: parse(obs.obsEndDateTime.substring(0, 16), "yyyy-MM-dd'T'HH:mm", refDate)
            };
          })}
          columns={columns}
          autoHeight={true}
          hideFooterPagination={true}
          disableSelectionOnClick={true}
          hideFooter={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
        </div>
        </div>
      ) : (
        <Typography variant="h6">
          No multiday precip observations were submitted.
        </Typography>
      )}
    </Paper>
  );
}

export default StationMultidayObsList;
