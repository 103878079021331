import React, { useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StationWaterYearSummaryContext } from "../../contexts/StationWaterYearSummaryContext";
import Link from "@material-ui/core/Link";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress"
import { REQUEST_STATUS } from "../../hooks/useStationWaterYearSummary";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  searchCard: { display: "inline-block" },
  blackBorder: { border: "1px solid black" },
  redBorder: { border: "1px solid red" },
  greenBorder: { border: "1px solid green" },
  overviewHeader: {
    marginBottom: theme.spacing(2),
  },
}));

function ScrollToAnchor({requestStatus, location}) {
  useEffect(() => {
    
    let hash = location.hash;
    if (hash) {
        const anchor = (document).querySelector(
          hash
        );
        if (anchor) {
          anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }


  }, [requestStatus]);
  return <></>
}

function StationOverview() {
  const { stationWys, requestStatus, stationNumber, waterYear, error } = useContext(
    StationWaterYearSummaryContext
  );
  const classes = useStyles();
  let location = useLocation();

  if (requestStatus && requestStatus === REQUEST_STATUS.LOADING) {
    return (
      <Paper>
        <Container>
          <Box pt={2}>
            <Typography variant="h6">
            <CircularProgress style={{position: 'relative', top: '8px', marginRight: "10px"}}></CircularProgress>
              Loading the {waterYear} water year summary report for{" "}
              {stationNumber}
            </Typography>
          </Box>
          <Skeleton variant="text" height={60} animation="wave"></Skeleton>
          <Skeleton variant="rect" height={300} animation="wave" />
          <br />
        </Container>
      </Paper>
    );
  }

  if (requestStatus && requestStatus === REQUEST_STATUS.FAILURE) {
    return (
      <Paper>
        <Container>
          <Box pt={2}>
            <Typography variant="h6">
              An error occured: {error}
            </Typography>
          </Box>
        </Container>
      </Paper>
    );
  }

  if (!stationWys) {
    return <></>;
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} className={classes.overviewHeader}>
              <Typography variant="h6">
                Station Overview
              </Typography>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12} md={6} display="flex">
              <InputLabel>Station Number:</InputLabel>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography>{stationWys.station.stationNumber}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Station Name:</InputLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{stationWys.station.stationName}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Elevation:</InputLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{stationWys.station.elevation} ft.</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} className={classes.overviewHeader}>
              <Typography variant="h6" >
                Station Location
              </Typography>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>County:</InputLabel>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography>
                {stationWys.station.countyName}, {stationWys.station.stateAbbr}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Latitude:</InputLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{stationWys.station.latitude}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Longitude:</InputLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{stationWys.station.longitude}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <Link
              href={`https://wys-api.cocorahs.org/api/StationWysExcel?stationnumber=${stationWys.station.stationNumber}&year=${stationWys.waterYear}`}
            >
              <img
                src="/images/file_excel.png"
                style={{ height: "30px", position: "relative", top: "8px" }}
              />
              Download as Excel File
            </Link>
          </Grid>
          {/* }
                    <Grid item xs={6} sm={3}>
                        <InputLabel >Station Number:</InputLabel>
                    </Grid>
                    <Grid item xs={6} sm={3} display="flex"
    justify="center" justifyContent="flex-start"  style={{border:"1px solid red"}} >
                        <Typography>{stationWys.station.stationNumber}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InputLabel >Station Name:</InputLabel>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography>{stationWys.station.stationName}</Typography>
    </Grid> */}
        </Grid>
      </CardContent>
      <ScrollToAnchor requestStatus={requestStatus} location={location}></ScrollToAnchor>
    </Card>
  );
}

export default StationOverview;
