import React, { useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { StationWaterYearSummaryContext } from "../../contexts/StationWaterYearSummaryContext";

function parseIso8601(str) {
  // we assume str is a UTC date ending in 'Z'

  var parts = str.split("T"),
    dateParts = parts[0].split("-"),
    timeParts = parts[1].split("Z"),
    timeSubParts = timeParts[0].split(":"),
    timeSecParts = timeSubParts[2].split("."),
    timeHours = Number(timeSubParts[0]);

  return Date.UTC(
    Number(dateParts[0]),
    Number(dateParts[1]) - 1,
    Number(dateParts[2])
  );
}

function getDaysInWaterYearDailyPrcp(wys) {
  const list = wys.dailyPrcpObservations.map((obs) => {
    let prcp = obs.precipitation;
    if (prcp == null || prcp == "T" || prcp == "M") {
      prcp = 0;
    }
    return [parseIso8601(obs.obsDateTime), parseFloat(prcp)];
  });
  return list;
}
function getDaysInWaterYearMultidayPrcp(wys) {
  const list = wys.multiDayPrcpObservations.map((obs) => {
    let prcp = obs.precipitation;
    if (prcp == null || prcp == "T" || prcp == "M") {
      prcp = 0;
    }
    return [parseIso8601(obs.obsEndDateTime), parseFloat(prcp)];
  });
  return list;
}

function getMapOptions(wys) {
  const waterYear = wys.waterYear;
  const startYear = waterYear - 1;

  return {
    chart: {
      defaultSeriesType: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `${waterYear} Water Year (Oct ${startYear} - Sept ${waterYear}) Daily Precipitation`,
    },
    subtitle: {
      text: `Station: ${wys.station.stationNumber} ${wys.station.stationName}`,
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      x: -70,
      y: 52,
      floating: true,
      backgroundColor: {
        linearGradient: [0, 0, 0, 50],
        stops: [
          [0, "#FFFFFF"],
          [1, "#E0E0E0"],
        ],
      },
      shadow: true,
    },
    tooltip: {
      backgroundColor: {
        linearGradient: [0, 0, 0, 200],
        stops: [
          [0, "#FFFFFF"],
          [1, "#E0E0E0"],
        ],
      },
      style: {
        padding: 10,
        fontWeight: "bold",
        "line-height": 2,
      },
      //  borderWidth: 1,
      //borderColor: '#AAA'
    },
    plotOptions: {
      column: {
        pointPadding: 0.2, // 0.2
        borderWidth: 0,
      },
    },
    xAxis: {
      //startOnTick: true,
      endOnTick: false,
      type: "datetime",
      labels: {
        formatter: function () {
          return (
            '<span style="">\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 ' +
            Highcharts.dateFormat("%b %y", this.value) +
            "</span>"
          );
        },
      },
      tickInterval: 24 * 3600 * 1000 * 30,
      min: Date.UTC(startYear, 9, 1),
      max: Date.UTC(waterYear, 8, 30),
      plotBands: [
        {
          color: "#FEFFE6",
          from: Date.UTC(startYear, 10, 1),
          to: Date.UTC(startYear, 11, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(startYear, 12, 1),
          to: Date.UTC(waterYear, 1, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(waterYear, 2, 1),
          to: Date.UTC(waterYear, 3, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(waterYear, 4, 1),
          to: Date.UTC(waterYear, 5, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(waterYear, 6, 1),
          to: Date.UTC(waterYear, 7, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(waterYear, 8, 1),
          to: Date.UTC(waterYear, 9, 1),
        },
      ],
      // tickInterval: 24 * 3600 * 1000
      // categories: []
    },
    yAxis: {
      title: {
        text: "Precipitation Amount (inches)",
      },
      min: 0,
    },
    series: [
      {
        name: "Daily Prcp",
        type: "column",
        color: "#4572A7", // blue
        pointWidth: 2,
        shadow: false,
        data: getDaysInWaterYearDailyPrcp(wys),
      },
      {
        name: "Multiday Prcp",
        type: "column",
        color: "#89A54E",
        pointWidth: 2,
        shadow: false,
        data: getDaysInWaterYearMultidayPrcp(wys),
      },
    ],
  };
}

function DailyStationPrecipChart() {
  const { stationWys } = useContext(StationWaterYearSummaryContext);

  if (!stationWys) {
    return <></>;
  }

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={getMapOptions(stationWys)}
      />
    </div>
  );
}

export default DailyStationPrecipChart;
