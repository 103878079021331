import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from "@material-ui/data-grid";
import CustomToolbar from './CustomToolBar';
import { StateWaterYearSummaryContext } from "../contexts/StateWaterYearSummaryContext";
import StateWysOverview from "./StateWysOverview";
import { REQUEST_STATUS } from "../hooks/useStateWaterYearSummary";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { precipCellRenderer, snowCellRenderer } from "../constants"

const useStyles = makeStyles({
  root: {
    minWidth: 650,
    minHeight: 700,
    ".MuiDataGrid-cell": {
        lineHeight: "30px !important",
        minHeight: "30px !important",
        maxHeight: "30px !important",
    },
    "& .super-app-theme--cell": {
      lineHeight: "30px !important",
      minHeight: "30px !important",
      maxHeight: "30px !important",
    },
    "& .super-app-theme--cell2": {
        backgroundColor: "rgba(224, 183, 60, 0.55)",
        color: "#1a3e72",
        fontWeight: "600",
      },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      backgroundColor: "#d47483",
      color: "#1a3e72",
      fontWeight: "600",
    },
  },
  table: {
    minWidth: 650,
    minHeight: 600,
  },
});

/*
  {"stationNumber":"CO-AD-119","stationName":"Thornton 1.9 ESE","latitude":39.8786,"longitude":-104.9246,"stateCode":8,"countyCode":1,
  "stateName":"Colorado","stateAbbr":"CO","countyName":"Adams","countyAbbr":"AD","elevation":5083,"firstObsDate":"2019-10-01T00:00:00",
  "lastObsDate":"2019-12-05T00:00:00","dailyPrecipObsCount":61,"dailyPrecipSum":1.83,"multidayPrecipSum":0.00,"totalPrecipSum":1.83,
  "daysWithPrecip":8,"daysWithTrace":0,"snowfallSum":20.10,"daysCoveredByMultidayObs":0,"daysWithSnowfall":5,"daysWithSnowOnGround":0,
  "hailObsCount":0,"multidayPrecipObsCount":0,"maxDailyPrecip":0.00,"maxDailySnowfall":7.10,"maxMultidayPrecip":0.00,"prism30YrAvgPrcp":null,"daysCoveredByAllPrecipObs":61}
  
  */
 //  cellClassName: "super-app-theme--cell",
  

const columns = [
  {
    field: "stationNumber",
    headerName: "Station Number",
    width: 180,
    renderCell: (params) => (
        <Link href={params.row.link} >{params.value}</Link>
    ),
  },
  {
    field: "stationName",
    headerName: "Station Name",
    width: 170,
  },
  {
    field: "daysCoveredByAllPrecipObs",
    headerName: "Days Covered by All Obs",
    type: "number",
    width: 120,
  },
  {
    field: "totalPrecipSum",
    headerName: "Precip Sum",
    type: "number",
    width: 150,
    renderCell: precipCellRenderer
  },
  {
    field: "snowfallSum",
    headerName: "Snowfall Sum",
    type: "number",
    width: 165,
    renderCell: snowCellRenderer
  },
  {
    field: "dailyPrecipObsCount",
    headerName: "Daily Obs Count",
    type: "number",
    width: 150,
  },
  {
    field: "multidayPrecipObsCount",
    headerName: "Multiday Obs Count",
    type: "number",
    width: 180,
  },
  {
    field: "hailObsCount",
    headerName: "Hail Obs Count",
    type: "number",
    width: 150,
  },
  {
    field: "stateName",
    headerName: "State",
    width: 170,
    hide: true
  },
  {
    field: "countyName",
    headerName: "County",
    width: 170,
    hide: true
  },
  {
    field: "maxDailyPrecip",
    headerName: "Max Daily Precip",
    type: "number",
    width: 160,
    hide: true,
    renderCell: precipCellRenderer
  },
  {
    field: "maxDailySnowfall",
    headerName: "Max Snowfall",
    type: "number",
    width: 160,
    hide: true,
    renderCell: snowCellRenderer
  },
  {
    field: "maxMultidayPrecip",
    headerName: "Max Multiday Precip",
    type: "number",
    width: 160,
    hide: true,
    renderCell: precipCellRenderer
  },
  {
    field: "daysWithSnowfall",
    headerName: "Days with Snowfall",
    type: "number",
    width: 160,
    hide: true
  },
  {
    field: "daysWithSnowOnGround",
    headerName: "Days with Snow on Ground",
    type: "number",
    width: 160,
    hide: true
  },
];

function StationWaterYearSummaryList() {
  const classes = useStyles();
  const { stateWys, requestStatus } = useContext(StateWaterYearSummaryContext);
  const history = useHistory();

  const handleRowClick = (param, event) => {
    history.push(`/station/${param.id}/${stateWys.waterYear}`);
  };

  if (requestStatus && requestStatus === REQUEST_STATUS.LOADING) {
    return (
      <Paper>
        <Container>
          <Skeleton variant="text" height={60} animation="wave"></Skeleton>
          <Skeleton variant="rect" height={300} animation="wave" />
          <br />
        </Container>
      </Paper>
    );
  }

  if (!stateWys) {
    return <></>;
  }

  return (
    <Paper style={{height: '100%'}}>
      <StateWysOverview />
      <Divider />
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
      <DataGrid
        className={classes.root}
        density="compact"
        rows={stateWys.stationWaterYearTotals.map((wys) => {
          return { ...wys, 
            id: wys.stationNumber,
            link: `/station/${wys.stationNumber}/${stateWys.waterYear}` };
        })}
        columns={columns}
        defaultPageSize={100}
        onRowClick={handleRowClick}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      </div>
      </div>
    </Paper>
          //  onRowClick={handleRowClick}
  );
}

export default StationWaterYearSummaryList;
