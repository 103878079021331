import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { Select, CircularProgress } from "@material-ui/core/";
import { data } from "../StateProvinceData";
import { StateWaterYearSummaryContext } from "../contexts/StateWaterYearSummaryContext";
import { REQUEST_STATUS } from "../hooks/useStateWaterYearSummary";
import { green } from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";
import { WATER_YEAR_RANGE } from "../constants"

import {
  Grid,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";

export const COUNTRY_ABBRS = {
  USA: "USA",
  BHS: "BHS",
  CAN: "CAN",
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  searchCard: { display: "inline-block" },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {

  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function getDefaultCountry(abbr) {
  if (abbr === COUNTRY_ABBRS.CAN || abbr === COUNTRY_ABBRS.BHS) {
    return abbr;
  } else {
    return COUNTRY_ABBRS.USA;
  }
}

function getDefaultState(abbr) {
  if (!abbr) {
    return COUNTRY_ABBRS.USA;
  } else if (abbr === COUNTRY_ABBRS.CAN || abbr === COUNTRY_ABBRS.BHS) {
    return COUNTRY_ABBRS.USA;
  } else {
    return abbr;
  }
}

function getDefaultShowStates(abbr) {
  if (abbr === COUNTRY_ABBRS.CAN || abbr === COUNTRY_ABBRS.BHS) {
    return false;
  } else {
    return true;
  }
}

function StateWysSearchForm() {
  const {
    stateAbbr,
    waterYear,
    setStateAbbr,
    setWaterYear,
    requestStatus,
    error,
  } = useContext(StateWaterYearSummaryContext);
  const history = useHistory();

  let initState = getDefaultState(stateAbbr);
  const classes = useStyles();
  const [country, setCountry] = useState(getDefaultCountry(stateAbbr));
  const [state, setState] = useState(initState);
  const [showStateText, setShowStateText] = useState(initState === COUNTRY_ABBRS.USA);
  const usaStates = data.filter(function (x) {
    return x.country == "USA";
  });
  const [showStates, setShowStates] = useState(
    getDefaultShowStates(stateAbbr)
  );
  const [stateProvinceData] = useState(usaStates);
  const [selectedWaterYear, setSelectedWaterYear] = useState(waterYear);


  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setShowStates(event.target.value === COUNTRY_ABBRS.USA);
    setShowStateText((event.target.value === COUNTRY_ABBRS.USA) && (state === COUNTRY_ABBRS.USA));
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    setShowStateText(event.target.value === COUNTRY_ABBRS.USA);
  };

  const handleWaterYearChange = (event) => {
    setSelectedWaterYear(event.target.value);
  };

  const onSearchClick = (event) => {
    let selectedState = state;
    // if CAN or BHS, set state to country code
    if (country !== COUNTRY_ABBRS.USA) {
      selectedState = country;
      setShowStateText(false);
    }

    // do not run if the entire US is selected
    if (selectedState !== COUNTRY_ABBRS.USA) {
      setStateAbbr(selectedState);
      setWaterYear(selectedWaterYear);
      history.push(`/${selectedState}/${selectedWaterYear}`);
    } else {
      setShowStateText(true);
    }
  };

  useEffect(() => {
    setShowStateText(stateAbbr === COUNTRY_ABBRS.USA);
    setSelectedWaterYear(waterYear);
    setState(getDefaultState(stateAbbr));
  }, [stateAbbr, waterYear]);

  return (
    <Card className={classes.searchCard}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Search Water Year Summary Reports
        </Typography>
        <FormControl className={classes.formControl}>
          <InputLabel id="year-select-label">Water Year</InputLabel>
          <Select
            labelId="year-select-label"
            id="year-select"
            value={selectedWaterYear}
            onChange={handleWaterYearChange}
          >
            {WATER_YEAR_RANGE.map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="country-select-label">Country</InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            value={country}
            onChange={handleCountryChange}
          >
            <MenuItem value={COUNTRY_ABBRS.USA}>USA</MenuItem>
            <MenuItem value={COUNTRY_ABBRS.CAN}>Canada</MenuItem>
            <MenuItem value={COUNTRY_ABBRS.BHS}>Bahamas</MenuItem>
          </Select>
        </FormControl>
        {showStates == true && (
          <FormControl className={classes.formControl}>
            <InputLabel id="state-select-label">State</InputLabel>
            <Select
              labelId="state-select-label"
              id="state-select"
              value={state}
              onChange={handleStateChange}
            >
              {stateProvinceData.map(({ label, key }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </CardContent>
      <CardActions style={{ width: "90%", justifyContent: "flex-end" }}>
        {showStateText && <Typography >Select a State to Search On</Typography>}
      <div className={classes.wrapper}>
        <Button
          size="small"
          variant="contained"          
          color="primary"
          disabled={showStateText || (requestStatus === REQUEST_STATUS.LOADING)}
          onClick={onSearchClick}
        >
          Search
        </Button>
        {(requestStatus === REQUEST_STATUS.LOADING) && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </CardActions>
    </Card>
  );
}

export default StateWysSearchForm;
