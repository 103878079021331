import React, { useEffect } from "react"
import StationWaterYearSummaryList from "./StationWaterYearSummaryList";
import StateWysSearchForm from "./StateWysSearchForm";
import { StateWaterYearSummaryProvider } from '../contexts/StateWaterYearSummaryContext';
import { Container } from "@material-ui/core";
import { useLocation } from "react-router-dom";

function StateWaterYearSummary({ children}) {
    let location = useLocation();

    useEffect(() => {
        console.log("state location change")
    }, [location]);

    return (
        <StateWaterYearSummaryProvider >
          <StateWaterYearSummarNoProvider>{children}</StateWaterYearSummarNoProvider>
        </StateWaterYearSummaryProvider>
      );
}

function StateWaterYearSummarNoProvider({ children}) {

    return (
        <>
        <Container  maxWidth="sm">
            <StateWysSearchForm />
        </Container>
            <StationWaterYearSummaryList ></StationWaterYearSummaryList>
            { children}
        </>
    )
}

export default StateWaterYearSummary;