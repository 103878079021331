import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Grid, Typography, Paper } from '@material-ui/core';
import Header from './components/Header';
import StateWaterYearSummary from './components/StateWaterYearSummary';
import StationWaterYearSummary from './components/station/StationWaterYearSummaryLongPage';
import About from './components/About'
import Help from './components/Help'
import Feedback from './components/Feedback'

// #b0c4de, #ececec
function App() {
  return (
    <Router>
      <Grid container direction="column">
        <Grid item>
          <Header />
        </Grid>
        <Grid item>
            <Grid item container>
              <Grid item xs={false} md={1} xl={2} />
              <Grid item xs={12} md={10} xl={8} >
                <Paper style={{ backgroundColor: '#b0c4de', marginTop: "20px", padding: "10px" }} >
                  <Switch>            
                    <Route path="/about">
                      <About />
                    </Route>                    
                    <Route path="/help">
                      <Help />
                    </Route>
                    <Route path="/feedback">
                      <Feedback />
                    </Route>
                    <Route path="/station/:stationNumber/:waterYear">
                      <StationWaterYearSummary></StationWaterYearSummary>
                    </Route>
                    <Route path={["/:stateAbbr/:waterYear", "/"]}>
                      <StateWaterYearSummary />
                    </Route>
                  </Switch>
                </Paper>
              </Grid>
              <Grid item xs={false} md={1} xl={2} />
            </Grid>
        </Grid>
      </Grid>   
    </Router>
  );
}

export default App;
