import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  padded: {
      padding: theme.spacing(2),
  }
}));

function Feedback() {

    const classes = useStyles();
  return (
    <Paper className={classes.padded}>
      <Typography variant="h4" color="primary">Feedback Form</Typography>
      <iframe width="640px" height="1200px" 
      src="https://forms.office.com/Pages/ResponsePage.aspx?id=Aoi1r3r_sUurITZ_8uz8i8iI6LVSpFBAoffI3PJggfFUNVFPTkRKSVdXNjhZRjhCOEVTNDlRQk1RMS4u&embed=true" 
      frameborder="0" marginwidth="0" marginheight="0" 
      style={{border: "none", maxWidth:"100%"}} 
      allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
    </Paper>
  );
}

export default Feedback;
