import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MonthlyPrecipTotalsChart from "./MonthlyPrecipTotalsChart";
import AccumulatedStationPrecipChart from "./AccumulatedStationPrecipChart";
import DailyStationPrecipChart from "./DailyStationPrecipChart";
import StationOverview from "./StationOverview";
import { StationWaterYearSummaryProvider } from "../../contexts/StationWaterYearSummaryContext";
import StationMonthlySummaryList from "./StationMonthlySummaryList";
import StationDailyPrecipObsList from "./StationDailyPrecipObsList";
import StationMultidayPrecipObsList from "./StationMultidayPrecipObsList";
import StationDaysInWaterYear from "./StationDaysInWaterYear";
import StationHailObsList from "./StationHailObsList";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import StationWysNav from "./StationWysNav";
import Highcharts from "highcharts";
import { useEffect } from "react";
require("highcharts/modules/exporting")(Highcharts);

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#top"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}



function StationWaterYearSummary({ children }) {
  return (
    <StationWaterYearSummaryProvider>
      <StationWaterYearSummaryNoProvider>
        {children}
      </StationWaterYearSummaryNoProvider>
    </StationWaterYearSummaryProvider>
  );
}

function StationWaterYearSummaryNoProvider(props) {
  const { children } = props;

  return (
    <>
      <Box mb={1} id="top">
        <StationWysNav></StationWysNav>
      </Box>
      <Box id="overview">
        <StationOverview></StationOverview>
      </Box>
      <Box mt={2} mb={4}>
        <StationMonthlySummaryList></StationMonthlySummaryList>
      </Box>
      <Box mt={2} id="charts">
        <MonthlyPrecipTotalsChart></MonthlyPrecipTotalsChart>
      </Box>
      <Box mt={2}>
        <AccumulatedStationPrecipChart></AccumulatedStationPrecipChart>
      </Box>
      <Box mt={2}>
        <DailyStationPrecipChart></DailyStationPrecipChart>
      </Box>
      <Box mt={2} mb={4} id="days-in-wy">
        <StationDaysInWaterYear></StationDaysInWaterYear>
      </Box>
      <Box mt={2} mb={4} id="daily-obs">
        <StationDailyPrecipObsList></StationDailyPrecipObsList>
      </Box>
      <Box mt={2} mb={4} id="multiday-obs">
        <StationMultidayPrecipObsList></StationMultidayPrecipObsList>
      </Box>
      <Box mt={2} mb={4} id="hail-obs">
        <StationHailObsList></StationHailObsList>
      </Box>
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      {children}
    </>
  );
}

export default StationWaterYearSummary;
