import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import useStationWaterYearSummary from "../hooks/useStationWaterYearSummary";
import { CURRENT_WATER_YEAR, isWaterYearValid } from '../constants'

const StationWaterYearSummaryContext = createContext();

function StationWaterYearSummaryProvider({ children }) {
  let { stationNumber: initStation, waterYear: initWaterYear } = useParams();
  // validate water year
  if (!(isWaterYearValid(initWaterYear))) {
    initWaterYear = CURRENT_WATER_YEAR;
  }
  const {
    stationWys,
    stationNumber,
    setStationNumber,
    waterYear,
    setWaterYear,
    requestStatus,
    error,
  } = useStationWaterYearSummary(initStation, initWaterYear);

  return (
    <StationWaterYearSummaryContext.Provider
      value={{
        stationWys,
        setWaterYear,
        setStationNumber,
        stationNumber,
        waterYear,
        requestStatus,
        error,
      }}
    >
      {children}
    </StationWaterYearSummaryContext.Provider>
  );
}

export { StationWaterYearSummaryContext, StationWaterYearSummaryProvider };
