import React, { useContext } from "react";
import { StationWaterYearSummaryContext } from "../../contexts/StationWaterYearSummaryContext";
import ViewListIcon from '@material-ui/icons/ViewList';
import { HashLink } from "react-router-hash-link";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useHistory } from "react-router-dom";

function StationWysNav() {
  const { stationWys, requestStatus, stationNumber, waterYear, error } =
    useContext(StationWaterYearSummaryContext);
  const history = useHistory();

  if (!stationWys) {
    return <></>;
  }

  const stationListUrl = (stationWys.station)? `/${stationWys.station.stateAbbr}/${stationWys.waterYear}` : "/";

  return (
    <>
      <Button
        variant="contained"
        size="small"
        style={{ marginRight: "10px" }}
        startIcon={<ViewListIcon />}
        onClick={() =>
          history.push(stationListUrl)
        }
      >
        Station List
      </Button>
      <ButtonGroup
        size="small"
        variant="contained"
        color="primary"
        aria-label="outlined primary button group"
      >
        <Button>
          <HashLink
            smooth
            to={"#overview"}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Overview
          </HashLink>
        </Button>
        <Button>
          <HashLink
            smooth
            to={"#charts"}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Charts
          </HashLink>
        </Button>
        <Button>
          <HashLink
            smooth
            to={"#days-in-wy"}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Days in Water Year
          </HashLink>
        </Button>
        <Button>
          <HashLink
            smooth
            to={"#daily-obs"}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Daily Precip Obs
          </HashLink>
        </Button>
        <Button>
          <HashLink
            smooth
            to={"#multiday-obs"}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Multiday Precip Obs
          </HashLink>
        </Button>
        <Button>
          <HashLink
            smooth
            to={"#hail-obs"}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Hail Obs
          </HashLink>
        </Button>
      </ButtonGroup>
    </>
  );
}

export default StationWysNav;
