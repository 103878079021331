import { Card, CardContent, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { StateWaterYearSummaryContext } from "../contexts/StateWaterYearSummaryContext";
import Link from "@material-ui/core/Link";

function StateWysOverview() {
  const { stateWys } = useContext(StateWaterYearSummaryContext);

  if (!stateWys) {
    return <div>Loading...</div>;
  }

  const search = " ";
  const replaceWith = "-";
  const stateName = stateWys.state.stateName.split(search).join(replaceWith);
  // can't us replaceAll in IE 11
  // stateWys.state.stateName.replaceAll(' ', '-')

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" style={{ paddingLeft: "8px" }}>
          <img
            src={`https://www.cocorahs.org/images/icons/stateflags/${stateName}-Flag-64.png`}
            style={{
              height: "40px",
              position: "relative",
              top: "10px",
              right: "10px",
            }}
          ></img>
          Station Water Year Summary Reports for {stateWys.state.stateName}{" "}
          {stateWys.waterYearText}
        </Typography>
        <Link
          href={`https://wys-api.cocorahs.org/api/StateWysExcel?state=${stateWys.state.stateAbbr}&year=${stateWys.waterYear}`}
        >
          <img
            src="/images/file_excel.png"
            style={{ height: "30px", position: "relative", top: "8px" }}
          />
          Download as Excel File
        </Link>
      </CardContent>
    </Card>
  );
}

export default StateWysOverview;
