import React, { useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { StationWaterYearSummaryContext } from '../../contexts/StationWaterYearSummaryContext';

function parseIso8601(str) {
  // we assume str is a UTC date ending in 'Z'

  var parts = str.split("T"),
    dateParts = parts[0].split("-"),
    timeParts = parts[1].split("Z"),
    timeSubParts = timeParts[0].split(":"),
    timeSecParts = timeSubParts[2].split("."),
    timeHours = Number(timeSubParts[0]);

  return Date.UTC(
    Number(dateParts[0]),
    Number(dateParts[1]) - 1,
    Number(dateParts[2])
  );
}

function getDaysInWaterYearDailyPrcp(wys) {
  const list = wys.dailyPrcpObservations.map((obs) => {
    let prcp = obs.precipitation;
    if (prcp == null || prcp == "T" || prcp == "M") {
      prcp = 0;
    }
    return [parseIso8601(obs.obsDateTime), parseFloat(prcp)];
  });
  return list;
  /*
    var list = [];
    $(wys.dailyPrcpObservations).each(function (i, item) {
        var prcp = item.precipitation;
        if ((prcp == null)  || (prcp == "T") || (prcp == "M"))
            prcp = 0; 

        list.push([parseIso8601(item.obsDateTime), parseFloat(prcp)]);

    });
    return list;
    */
}
function getDaysInWaterYearMultidayPrcp(wys) {
  const list = wys.multiDayPrcpObservations.map((obs) => {
    let prcp = obs.precipitation;
    if (prcp == null || prcp == "T" || prcp == "M") {
      prcp = 0;
    }
    return [parseIso8601(obs.obsEndDateTime), parseFloat(prcp)];
  });
  return list;
  /*
    var list = [];
    $(wys.multiDayPrcpObservations).each(function (i) {
        var prcp = wys.multiDayPrcpObservations[i].precipitation;
        if ((prcp == null)  || (prcp == "T") || (prcp == "M"))
            prcp = 0;
        list.push([parseIso8601(wys.multiDayPrcpObservations[i].obsEndDateTime), parseFloat(prcp)]);

    });
    mdList = list;
    return list;
    */
}
function getDaysInWaterAccPrcp(wys) {
  const list = wys.daysInWaterYear.map((wyDay) => {
    return [parseIso8601(wyDay.day), parseFloat(wyDay.accumulatedPrcp)];
    /*
        if ((wyDay == 0) && isNaN(parseFloat(wyDay.accumulatedPrcp))) {
            return [parseIso8601(wyDay.day), 0];
        }
        else {
            return [parseIso8601(wyDay.day), parseFloat(wyDay.accumulatedPrcp)];
        }  
        */
  });
  return list;
  /*
    var list = [];
    $(wys.daysInWaterYear).each(function (j) {
        if ((j == 0) && isNaN(parseFloat(wys.daysInWaterYear[j].accumulatedPrcp))) {
            list.push([parseIso8601(wys.daysInWaterYear[j].Day), 0]);
        }
        else {
            list.push([parseIso8601(wys.daysInWaterYear[j].Day), parseFloat(wys.daysInWaterYear[j].accumulatedPrcp)]);
        }            
    });

    return list;
    */
}

function getDaysInWaterPrismAccPrcp(wys) {
  let list = [];
  let total = 0;
  // sum up the monthly averages to produce an accumulated value at the end of each month in the water year
  list.push([Date.UTC(wys.waterYear - 1, 9, 1), total]);
  total += wys.prism30YrMonthlyAvgPrcp.oct;
  list.push([Date.UTC(wys.waterYear - 1, 10, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.nov;
  list.push([Date.UTC(wys.waterYear - 1, 11, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.dec;
  list.push([Date.UTC(wys.waterYear, 0, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.jan;
  list.push([Date.UTC(wys.waterYear, 1, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.feb;
  list.push([Date.UTC(wys.waterYear, 2, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.mar;
  list.push([Date.UTC(wys.waterYear, 3, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.apr;
  list.push([Date.UTC(wys.waterYear, 4, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.may;
  list.push([Date.UTC(wys.waterYear, 5, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.jun;
  list.push([Date.UTC(wys.waterYear, 6, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.jul;
  list.push([Date.UTC(wys.waterYear, 7, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.aug;
  list.push([Date.UTC(wys.waterYear, 8, 0), total]);
  total += wys.prism30YrMonthlyAvgPrcp.sep;
  list.push([Date.UTC(wys.waterYear, 9, 0), total]);

  return list;
}

function getAccChartSeries(wys) {
  const startYear = wys.waterYear - 1;
  var series = [
    {
      type: "line",
      name: "Accumulated Prcp",
      data: getDaysInWaterAccPrcp(wys),
      pointInterval: 24 * 3600 * 1000,
      pointStart: Date.UTC(startYear, 9, 1),
      lineWidth: 1,
      marker: {
        radius: 2,
        states: {
          hover: {
            radius: 6,
          },
        },
      },
    } /*
    , {
        name: 'Daily Prcp',
        type: 'column',
        color: '#4572A7', // blue
        shadow: false,
        data: getDaysInWaterYearDailyPrcp(wys)
    }, */ /*{
        name: 'Multiday Prcp',
        type: 'column',
        color: '#89A54E', // 3D96AE
        shadow: false,

        data: getDaysInWaterYearMultidayPrcp(wys)
    }*/,
  ];
  /*
    if (wys.HasPrismData) {
        var prismSeries = {
            type: 'line',
            name: '30 Year Avg by PRISM',
            data: getDaysInWaterPrismAccPrcp(wys)
        };
        series.push(prismSeries);
    }  */
  return series;
}

function getMapOptions(wys) {
  const waterYear = wys.waterYear;
  const startYear = waterYear - 1;

  let options = {
    chart: {
      defaultSeriesType: "line",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `${waterYear} Water Year (Oct ${startYear} - Sept ${waterYear}) Accumulated Precipitation`,
    },
    subtitle: {
      text: `Station: ${wys.station.stationNumber} ${wys.station.stationName}`,
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      x: -70,
      y: 52,
      floating: true,
      backgroundColor: {
        linearGradient: [0, 0, 0, 50],
        stops: [
          [0, "#FFFFFF"],
          [1, "#E0E0E0"],
        ],
      },
      shadow: true,
    },
    tooltip: {
      backgroundColor: {
        linearGradient: [0, 0, 0, 200],
        stops: [
          [0, "#FFFFFF"],
          [1, "#E0E0E0"],
        ],
      },
      style: {
        padding: 10,
        fontWeight: "bold",
        "line-height": 2,
      },
      pointFormat: "<span style=\"color:{point.color}\">&#x25CF</span> {series.name}: {point.y:.2f}",
      //  borderWidth: 1,
      //borderColor: '#AAA'
    },
    plotOptions: {
      column: {
        pointPadding: 0.2, // 0.2
        borderWidth: 0,
      },
    },
    xAxis: {
      //startOnTick: true,
      endOnTick: false,
      type: "datetime",
      labels: {
        formatter: function () {
          return (
            '<span style="">\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 ' +
            Highcharts.dateFormat("%b %y", this.value) +
            "</span>"
          );
        },
      },
      tickInterval: 24 * 3600 * 1000 * 30,
      min: Date.UTC(startYear, 9, 1),
      max: Date.UTC(waterYear, 8, 30),
      plotBands: [
        {
          color: "#FEFFE6",
          from: Date.UTC(startYear, 10, 1),
          to: Date.UTC(startYear, 11, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(startYear, 12, 1),
          to: Date.UTC(waterYear, 1, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(waterYear, 2, 1),
          to: Date.UTC(waterYear, 3, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(waterYear, 4, 1),
          to: Date.UTC(waterYear, 5, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(waterYear, 6, 1),
          to: Date.UTC(waterYear, 7, 1),
        },
        {
          color: "#FEFFE6",
          from: Date.UTC(waterYear, 8, 1),
          to: Date.UTC(waterYear, 9, 1),
        },
      ],
      // tickInterval: 24 * 3600 * 1000
      // categories: []
    },
    yAxis: {
      title: {
        text: "Precipitation Amount (inches)",
      },
      min: 0,
    },
    series: [
      {
        type: "line",
        name: "Accumulated Prcp",
        data: getDaysInWaterAccPrcp(wys),
        pointInterval: 24 * 3600 * 1000,
        pointStart: Date.UTC(startYear, 9, 1),
        lineWidth: 1,
        marker: {
          radius: 2,
          states: {
            hover: {
              radius: 6,
            },
          },
        },
      },
      {
        name: "Daily Prcp",
        type: "column",
        color: "#4572A7", // blue
        pointWidth: 2,
        shadow: false,
        data: getDaysInWaterYearDailyPrcp(wys),
      },
      {
        name: "Multiday Prcp",
        type: "column",
        color: "#89A54E",
        pointWidth: 2,
        shadow: false,
        data: getDaysInWaterYearMultidayPrcp(wys),
      }/*,
      {
        type: "line",
        name: "30 Year Avg by PRISM",
        data: getDaysInWaterPrismAccPrcp(wys),
      },
      
    if (wys.HasPrismData) {
        var prismSeries = {
            type: 'line',
            name: '30 Year Avg by PRISM',
            data: getDaysInWaterPrismAccPrcp(wys)
        };
        series.push(prismSeries);
    }  */
    ],
  };

  if (wys.hasPrismData) {
    options.series.push(
      {
        type: "line",
        name: "30 Year Avg by PRISM",
        data: getDaysInWaterPrismAccPrcp(wys),
      }
    );
  }

  return options;
}

/*
function getMapOptions(wys) {
  const waterYear = wys.waterYear;
  const startYear = waterYear - 1;

  return {
    chart: {
      defaultSeriesType: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `${waterYear} Water Year (Oct ${startYear} - Sept  ${waterYear}) Accumulated Precipitation`,
    },
    subtitle: {
      text: `Station: ${wys.station.stationNumber} ${wys.station.stationName}`,
      legend: {
        align: "right",
        verticalAlign: "top",
        x: -200,
        y: 52,
        floating: true,
        backgroundColor: {
          linearGradient: [0, 0, 0, 50],
          stops: [
            [0, "#FFFFFF"],
            [1, "#E0E0E0"],
          ],
        },
        shadow: true,
      },
      tooltip: {
        shared: true,
        backgroundColor: {
          linearGradient: [0, 0, 0, 200],
          stops: [
            [0, "#FFFFFF"],
            [1, "#E0E0E0"],
          ],
        },
        style: {
          padding: 10,
          fontWeight: "bold",
          "line-height": 2,
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          pointWidth: 2,
        },
      },
      xAxis: {
        startOnTick: true,
        endOnTick: false,
        showLastLabel: false,
        showFirstLabel: true,
        type: "datetime",
        labels: {
          formatter: function () {
            return (
              '<span style="">\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 ' +
              Highcharts.dateFormat("%b %y", this.value) +
              "</span>"
            );
          },
        },
        // tickPixelInterval: 87
        tickInterval: 24 * 3600 * 1000 * 30,
        min: Date.UTC(startYear, 9, 1),
        max: Date.UTC(waterYear, 8, 30),
        plotBands: [
          {
            color: "#FEFFE6",
            from: Date.UTC(startYear, 10, 1),
            to: Date.UTC(startYear, 11, 1),
          },
          {
            color: "#FEFFE6",
            from: Date.UTC(startYear, 12, 1),
            to: Date.UTC(waterYear, 1, 1),
          },
          {
            color: "#FEFFE6",
            from: Date.UTC(waterYear, 2, 1),
            to: Date.UTC(waterYear, 3, 1),
          },
          {
            color: "#FEFFE6",
            from: Date.UTC(waterYear, 4, 1),
            to: Date.UTC(waterYear, 5, 1),
          },
          {
            color: "#FEFFE6",
            from: Date.UTC(waterYear, 6, 1),
            to: Date.UTC(waterYear, 7, 1),
          },
          {
            color: "#FEFFE6",
            from: Date.UTC(waterYear, 8, 1),
            to: Date.UTC(waterYear, 9, 1),
          },
        ],
      },
      yAxis: {
        title: {
          text: "Precipitation Amount (inches)",
        },
        min: 0,
      },
      series: [
        {
          name: "Daily Prcp",
          type: "column",
          color: "#4572A7", // blue
          pointWidth: 2,
          shadow: false,
          data: getDaysInWaterYearDailyPrcp(wys),
        },
        {
          name: "Multiday Prcp",
          type: "column",
          color: "#89A54E",
          pointWidth: 2,
          shadow: false,
          data: getDaysInWaterYearMultidayPrcp(wys),
        },
      ],
    },
  };
}
*/

function AccumulatedStationPrecipChart() {
  const {stationWys} = useContext(StationWaterYearSummaryContext);

  if (!stationWys) {
    return <></>;
  }

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={getMapOptions(stationWys)}
      />
    </div>
  );
}

export default AccumulatedStationPrecipChart;
